import request from '@/utils/axios'

/** 获取提现列表 */
export function getList(params) {
  return request({
    url: '/withdraw',
    method: 'get',
    params
  })
}

/** 提现审核 */
export function witExamine(data) {
  return request({
    url: '/withdraw',
    method: 'post',
    data
  })
}
